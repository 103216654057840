.project-sample-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 93%;
  border-radius: 9px;
  margin: 20px auto;
  /* border: 1px solid #F7F7F7; */
  /* background-color: hsl(0, 0%, 100%); */
  /* padding: 20px 20px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.project-sample-container-mobile {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  /* max-width: 93%; */
  border-radius: 9px;
  margin: 20px auto;
  /* border: 1px solid #F7F7F7; */
  /* background-color: hsl(0, 0%, 100%); */
  /* padding: 20px 20px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.flagship-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: -260px;
  z-index: 0;
  height: 20%;
  width: auto;
}

.scroll-button {
  background: none;
  border: none;
  color: rgb(17, 111, 235);
  font-size: 2rem;
  cursor: pointer;
  margin-top: 20px;
  animation: bounce 2s infinite;
}

.scroll-button:hover {
  color: #6197fb;
}

/* Chevron Animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(10px);
  }
  60% {
    transform: translateY(5px);
  }
}

@keyframes fadeIn {
    0% { 
      transform: translateY(-75%);
      opacity: 0;
      scale: 70%;
     }
    50% { 
      transform: translateY(0);
      opacity: 1;
    }
}
@keyframes fadeOut {
    0% { opacity: 1; }
    10% { opacity: 0; }
}

.fade-out {
  animation: fadeIn 2.1s ;
}

.fade-in {
  animation: fadeOut 2.1s ;
}

.scroll-container {
  scroll-snap-type: y mandatory; /* Enables vertical snapping */
  /* overflow-y: scroll; */
  height: 100vh; /* Full viewport height for snapping */
  scroll-behavior: smooth;
  
}

/* Individual Sections */
.scroll-section {
  scroll-snap-align: center; /* Snaps section to the top of the container */
  height: 100vh; /* Each section occupies the full viewport */
  display: flex;
   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
}

/* Highlight Transition on Snapping */
/* .scroll-section:focus-within {
  transform: scale(1.10);
  background-color: #888; 
} */


.flagship-image {
  width: 94%;
  scale: 75%;
  height: auto;
  display: block;
  object-fit: cover;
  border-radius: 15px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: red; /* Temporarily to test visibility */
  z-index: 1;
}


.project-sample-container2 {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 80%;
  border-radius: 9px;
  margin: 20px auto;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 20px 100px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-sampler {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 500px;
  border-radius: 9px;
  margin: 20px auto;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 20px;
  align-items: stretch;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-sampler1 {
  position: relative;
  width: 100%;
  height: 87%;
  display: flex;
  flex-direction: column;
  max-width: 80%;
  /* border-radius: 9px; */
  margin: 20px auto;
  /* margin-bottom: 5px; */
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 20px;
  align-items: stretch;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-sampler-item {
  position: relative;
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 92%;
  /* border-radius: 9px; */
  margin: 20px auto;
  margin-left: 0;
  /* margin-bottom: 5px; */
  border-top-right-radius: 59px;
  box-shadow: inset -11px 8px 8px rgba(23, 23, 23, 0.27);
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 40px;
  align-items: stretch;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.project-sampler-item-mobile {
  position: relative;
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  /* border-radius: 9px; */
  /* margin: 20px auto; */
  /* margin-left: 0; */
  /* margin-bottom: 5px; */
  border-top-right-radius: 59px;
  box-shadow: inset -11px 8px 8px rgba(0, 0, 0, 0.27);
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 20px;
  align-items: stretch;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.shop-name {
  font-family: 'IT Caslon No 224, serif,serif';
  font-size: 24px;
  font-weight: 0;
  /* padding-left: 100px; */
  width: 100%;
  width: 80%;
}

.shop-description {
  /* font-family: 'IT Caslon No 224, serif,serif'; */
  padding-left: 10%;
  font-size: 16px;
  width: 80%;
  display: flex;
  height: 30%;
  text-align: left;
  /* writing-mode:vertical-rl; */
}

.checkout-cart {
  width: 100%;
  border-radius: none;
  background-color: #212121;
}

.responsive-image {
  max-width: 98%;
  height: auto;
  /* scale: 95%; */
  display: block;   /* Removes any bottom space */
  border-radius: 3px;
  /* padding-top: 20px; */
  box-shadow: -10px 13px 10px 8px rgba(0, 0, 0, 0.3);
  margin: 0 auto;   /* Centers   the image */
}

.responsive-image2 {
  height: 100%;     /* Height adjusts automatically to maintain aspect ratio */
  width: 100%;
  
  display: block;   /* Removes any bottom space */
  border-radius: 3px;
  padding-top: 50px;
    
}

.thumbnails-container-div {
  display: flex;
  flex-direction: column;
}
.thumbnails-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 10px;
}

.thumbnails-container img {
  cursor: pointer;
  height: auto;
  object-fit: cover;
  border: 1px solid #ddd;
  transition: border 0.3s ease;
}

.thumbnails-container img:hover {
  border: 1px solid #000000;
}

.thumbnails-container img.selected {
  border: 2px solid #000000;
}


.checkout-buttons {
  width: 80%;
  scale: 80%;
}

.size-label {
  font-size: 14px;
  /* padding-right: 110%; */
}

.container-buttons {
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-end;
}

.cart-buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10%;
}

.cart-buttons-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  /* margin-top: 10%; */
}

.add-button {
  /* padding-right: 100px; */
  width: 100%;
}

.checkout-buttons2 {
  width: 100%;
  scale: 70%;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #212121;
  color: white;
  font-size: 1.7rem;
  /* display: inline-block; */
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.sale-items {
  height: 800;
  margin-bottom: 100px;
  /* background-image: url('../image/yellw.png'); */
  flex-direction: column;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 100px;
}

.sale-items-single {
  height: 800;
  margin-bottom: 100px;
  /* background-image:  url('../image/yellw.png'); */
  flex-direction: row;
  width: 98.6%;
  display: grid;
  margin-top: 100px;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 100px;
}

.sale-items-single-mobile {
  height: auto;
  /* margin-bottom: 100px; */
  flex-direction: row;
  width: 96.6%;
  display: grid;
  margin-top: 2%;
  grid-template-columns: repeat(1, 1fr);
  /* row-gap: 100px; */
}

.radio-buttons-container {
  display: grid;
  width: 70%;
  grid-template-columns: repeat(4, 1fr); /* Two columns */
  gap: 10px; /* Space between buttons */
}

.radio-buttons-container input[type="radio"] {
  display: none;
}


.radio-buttons-container label {
  display: block;
  padding: 6px;
  border-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-align: center;
  cursor: pointer;
  border: 2px solid grey;
  transition: background-color 0.3s ease;
}

.radio-buttons-container label:hover {
  border-color: black;
}

.radio-buttons-container input[type="radio"]:checked ~ label{
  border-color: #000000;
}


.qty {
  width: 30px;
}

  .project-image-container {
  width: 100%;
  height: auto; /* Adjust this height based on your design */
  display: flex;
  /* height: 10%; */
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
}

.project-image-container2 {
  width: 100%;
  height: 470px; /* Adjust this height based on your design */
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
}

.project-image-container:hover {
  transform: scale(1.07);
  transition-duration: 1.6s;
  border-radius: 9px;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.collection-image {
  height: 320px;
  width: 320px;
}

.project-image {
  width: 100%;
  height: auto;
  max-height: 550px;
  overflow: hidden;
  scale: 87%;
  justify-content: cover;
  transition: transform 0.9s ease, box-shadow 0.8s ease;
}

.project-image2 {
  max-width: 100%;
  height: auto;
  max-height: 560px;
  overflow:hidden;
  border-radius: 3px;
  scale: 90%;
  justify-content: cover;
  box-shadow: -10px 13px 10px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.9s ease, box-shadow 0.8s ease;
}

.project-image:hover {
  transform: scale(1.0);
  /* box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.3); */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.8s ease;
}

.project-link {
  /* scale: 100%; */
  margin-top: 10px;
  /* padding-top: 30px; */
  /* padding: 10px; */
}

.project-link:hover .overlay {
  opacity: 1; /* Show the overlay on hover */
}

.overlay-text {
  font-size: 1.2em;
  padding-bottom: 40px;
  text-align: center;
}

/* .project-details {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  align-items: start;
  width: 100%;
  text-align: center;
} */

.project-details {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, minmax(20%, 1fr));
  width: 100%;
  /* max-height: 100%; */
  align-items: center;
  /* animation: slideIn 1s forwards; */
  scale: 100%;
  text-align: center;
}

@keyframes slideIn {
    0% { 
      /* transform: translateX(-75%); */
      opacity: 0; }
    90% { 
      /* transform: translateX(0); */
      opacity: 1; }
}
@keyframes slideOut {
    0% { 
      transform: translateX(-100%);
      opacity: 1; }
    10% { 
      transform: translateX(0);
      opacity: 0; }
}

.slide-out {
  animation: fadeIn 5s ;
}

.slide-in {
  animation: fadeOut 5s ;
}


@keyframes fadeInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
    scale: 92%;
  }
  50% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fadeInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
    scale: 92%;
  }
  50% {
    transform: translateX(0);
    opacity: 1;
  }
}

.project-slide-in-right {
  animation: fadeInRight 2.4s;
}

.project-slide-in-left {
  animation: fadeInLeft 2.4s;
}

.project-details-mobile {
  display: grid;
  /* display: flex; */
  /* gap: 10px; */
  /* flex-direction: column; */
  overflow-x: none;
  grid-template-rows: repeat(1, minmax(1fr));
  width: 100%;
  max-height: 100%;
  align-items: center;
  scale: 100%;
  text-align: center;
}

.project-details2 {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, minmax(300px, 1fr)); /* Grid layout */
  width: 100%;
  text-align: center;
  align-items: start; /* Align all items to the top */
}


.details-box {
  display: flex;
  flex-direction: column;
  width: 300px; /* Standard width for all items */
  margin: 20px;
  text-align: center;
  justify-content: flex-end;
  /* padding-bottom: 10px; */
  height: 600px; /* Fix height for all boxes to standardize layout */
}

.details-box2 {
  display: flex;
  flex-direction: column;
  width: 350px; /* Standard width for all items */
  /* margin: 20px; */
  text-align: center;
  justify-content: flex-end;
  padding-bottom: 30px;
  height: 87%;
  /* height: auto; */
}

.details-box2-mobile {
  display: flex;
  flex-direction: column;
  width: auto; /* Standard width for all items */
  /* margin: 20px; */
  text-align: center;
  justify-content: flex-end;
  padding-bottom: 30px;
  height: 650px;
}

.project-details-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

.project-title {
  font-size: 1.2em;
  margin-bottom: 15px;
  font-weight: 700;
}

.project-title1 {
  font-size: 1.4em;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
  letter-spacing: -0.05em;
  font-stretch: 105%;
  text-decoration: underline; 
  font-family: 'ITC Caslon No 224, serif';
  font-weight: 400;
}

.project-title2 {
  margin-bottom: 55px;
  width: 100%;
  text-align: center;
  letter-spacing: -0.05em;
  /* text-decoration: underline;  */
  /* font-stretch: 105%; */
  font-size: 1.2em;
  letter-spacing: 1.2px;
  font-family: 'ITC Caslon No 224, serif';
  font-weight: 300;
  /* font-family: 'Space Mono', monospace; */
  /* font-size: clamp(3rem, 10vw, 10rem); */
  color: #212121;
  padding: 0rem clamp(1rem, 2vw, 3rem);
  border-radius: clamp(0.4rem, 0.75vw, 1rem);
}

.project-title3 {
  font-size: 2.2rem;
  margin-bottom: 15px;
  font-weight: 500;
  /* margin-bottom: 15px; */
  /* width: 100%; */
  text-align: center;
  letter-spacing: -0.05em;
  /* text-decoration: underline;  */
  /* font-stretch: 105%; */
  /* font-size: 1.7em;
  font-family: 'ITC Caslon No 224, serif';
  font-weight: 400; */
  /* font-family: 'Space Mono', monospace; */
  /* font-size: clamp(3rem, 10vw, 10rem); */
  color: #212121;
  padding: 0rem clamp(1rem, 2vw, 3rem);
  border-radius: clamp(0.4rem, 0.75vw, 1rem);
}


.project-title-collection {
  font-size: 1em;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
  letter-spacing: -0.02em;
  font-stretch: 105%;
  /* text-decoration: underline; */
  font-family: 'ITC Caslon No 224, serif';
  font-weight: 200;
}

.collection-title {
  font-size: 24px;
  margin-bottom: 15px;
  width: 100%;
  letter-spacing: -0.05em;
  font-stretch: 95%;
  /* text-decoration: underline; */
  font-family: 'ITC Caslon No 224, serif';
  font-weight: 300;
}

.section-title {
  width: 80%;
}

.scroll-erase {
  padding-left: 60%;
  padding-bottom: 10%;
  color: #000000;
  font-weight: 700;
  animation: fadeOut 0.6s forwards;
  animation-timeline: scroll();
}


.section-project-description {
  height: 20%;
  width: auto;
  background-color: #F7F7F7;
  margin-top: 85px;
  /* margin-bottom: 185px; */
  font-size: 1em;
  padding-left: 10%;
  padding-right: 10%;
  text-align: justify;
  color: #000000;
  justify-content: center;
  font-weight: 700;
  align-content: center;
  line-height: 1.6;
  opacity: 1;
  /* animation: fadeOut .001s backwards; */
  background-image: linear-gradient(to top, #f1f1f1 4%, #f9f9f9 30%);
  animation-timeline: scroll();
}


.section-project-description:hover {
  /* background-color: #f7f7f7; */
    color: #212121;
}

.section-project-description2 {
  height: 30%;
  width: auto;
  background-color: #F7F7F7;
  margin-top: 85px;
  /* margin-bottom: 185px; */
  font-size: .9em;
  padding-left: 10%;
  padding-right: 10%;
  text-align: justify;
  color: #000000;
  justify-content: center;
  font-weight: 700;
  align-content: center;
  line-height: 1.6;
  word-spacing: 2px;
  opacity: 1;
  /* animation: fadeOut 1s ; */
  background-image: linear-gradient(to top, transparent , #fff );
  /* animation-timeline: scroll(); */
}

@keyframes slideIn {
    0% { 
      transform: translateX(-100%);
       }
    50% { 
      transform: translateX(0);
       }
}
@keyframes slideOut {
    0% { opacity: 1; }
    10% { opacity: 0; }
}

.slide-out {
  animation: fadeIn 2s ;
}

.slide-in {
  animation: fadeIn 2s ;
}

/* @keyframes fadeIn {
    0% { opacity: 0; }
    15% { opacity: 1; }
}
@keyframes fadeOut {
    0% { opacity: 1; }
    10% { opacity: 0; }
} */

/* .fade-out {
  animation: fadeIn 5s forwards;
} */

/* .fade-in {
  animation: fadeOut 1s;
} */


.project-description2 {
  margin-top: 45px;
  margin-bottom: 45px;
  font-size: 0.8em;
  text-align: justify;
  width: 80%;
  color: #000000;
  font-weight: 700;
  align-content: center;
  line-height: 1.6;
  animation: fadeOut 2s backwards;
  animation-timeline: scroll();
}

.project-description3 {
  font-size: 1em;
  text-align: justify;
  width: 80%;
  color: #000000;
  font-weight: 700;
  align-content: center;
  line-height: 1.6;
  
}

.project-description {
  margin-top: 10px;
  font-size: 0.7em;
  /* padding: 15px 0; */
  /* font-family: 'ITC Caslon No 224, serif'; */
  text-align: center;
  /* align-items: center; */
  line-height: 1.6;
  word-spacing: 1px;
  letter-spacing: .4px;
  min-height: 60px;
  flex-shrink: 0;
  white-space: normal;
  /* white-space: nowrap; */
  /* overflow: hidden; */
}

.project-description-1 {
  margin-top: 30px;
  font-size: 0.7em;
  padding: 15px 0;
  /* font-family: 'ITC Caslon No 224, serif'; */
  text-align: center;
  /* align-items: center; */
  line-height: 1.6;
  word-spacing: 1px;
  letter-spacing: .4px;
  min-height: 60px;
  flex-shrink: 0;
  white-space: normal;
  /* white-space: nowrap; */
  /* overflow: hidden; */
}

.project-description-stock {
  /* margin-top: 30px; */
  font-size: 0.7em;
  /* padding: 15px 0; */
  /* font-family: 'ITC Caslon No 224, serif'; */
  text-align: center;
  /* align-items: center; */
  line-height: 1.6;
  word-spacing: 1px;
  letter-spacing: .4px;
  min-height: 10px;
  flex-shrink: 0;
  
  white-space: normal;
  /* white-space: nowrap; */
  /* overflow: hidden; */
}

/* Styling for subTitle */
.subTitle {
  font-size: 0.9em;
  height: 29px;
  font-weight: 500;
  padding-left: 40px;
  margin: 5px 0 0px 0;
  color: #333;
  /* border-bottom: 2px solid #ddd; */
  padding-bottom: 5px;
  width: 100%;
  text-align: left;
}

/* Styling for feature */
.feature-container {
  display: flex;
  flex-direction: row;
  overflow:visible;
  align-items: flex-start;
  /* gap: 4px; Adds space between features */
  width: 100%;
}

.tech-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px; /* Adds space between features */
  width: 100%;
}



.feature {
  font-size: 0.7em;
  margin: 0px 0;
  color: #0f0f0f;
  height: 119px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 20px;
  text-align: left;
  line-height: 1.6;
}

.feature::before {
  content: "•";
  margin-right: 5px;
  color: #007BFF; /* Change this color as needed */
  font-size: 1.5em;
}

.app-store-badge {
  width: 180px;
  height: auto;
  margin-top: 20px;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.app-store-badge:hover {
  transform: scale(1.05);
  transition-duration: 0.8s;
}


#contact {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#contact h2 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.8rem;
}

.contact-form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-columns: 0.8fr 1.3fr 1fr; */


  /* gap: 10px; */
  /* height: 90%; */
  /* flex-direction: row; */
  align-items: start;
}

.contact-form-wide {
  display: grid;
  grid-template-columns: 1fr 2fr;

  /* gap: 10px; */
  /* height: 90%; */
  /* flex-direction: row; */
  align-items: start;
}

.contact-form2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* gap: 10px; */
  /* height: 90%; */
  /* flex-direction: row; */
  align-items: start;
}

@media (max-width: 600px) {
  #contact-form {
    grid-template-columns: 1fr; /* Switch to one column */
  }
}


.buttons-left-container {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-right: 20%;
  /* grid-template-rows: repeat(1, 1fr); */
  justify-content: left;
  align-items: center;
}

.buttons-left-container2 {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  /* margin-right: 50%; */
  align-items: center;
}

#contact-form2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contact-form label {
  margin-bottom: 5px;
  font-weight: 600;
}


#contact-form input,
#contact-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 1.6rem;
  border-radius: 4px;
}

#contact-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #212121;
  color: white;
  height: 45px;
  font-size: 1.6rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  }

/* #contact-form button:hover {
  background-color: #000000;
} */

.shop-name {
  text-align: left;
  width: 70%;
  padding-bottom: 3%;
  line-height: 1.3;
  /* how do i make font taller? */
}

@media (max-width: 768px) {
  .project-sample-container {
    max-width: 100%;
    padding: 10x;
    padding: 0px;
  }

  .shop-description {
  /* font-family: 'IT Caslon No 224, serif,serif'; */
  width: 65%;
  writing-mode:sideways-lr
  }

  .project-image-container {
  position: relative;
  width: 100%;
  scale: 100%;
  min-height: auto;
  /* max-height: 632px; */
  /* justify-content: center; */
  border-radius: 9px;
  height: 500px;
}

  .section-project-description {
  height: 30%;
  width: 100%;
  background-color: #F7F7F7;
  margin-top: 85px;
  /* margin-bottom: 185px; */
  font-size: 1em;
  padding-left: 10%;
  padding-right: 10%;
  text-align: justify;
  color: #000000;
  justify-content: center;
  font-weight: 700;
  align-content: center;
  line-height: 1.6;
  opacity: 1;
   background-image: linear-gradient(to bottom, transparent , #fff );
  /* animation: fadeIn 1s backwards; */
  animation-timeline: scroll();
  }

  .section-project-description2 {
  height: 30%;
  width: 100%;
  background-color: #F7F7F7;
  margin-top: 85px;
  /* margin-bottom: 185px; */
  font-size: 1em;
  padding-left: 10%;
  padding-right: 10%;
  text-align: justify;
  color: #000000;
  justify-content: center;
  font-weight: 700;
  align-content: center;
  line-height: 1.6;
  /* opacity: 1;
  animation: fadeOut 1s backwards;
  animation-timeline: scroll(); */
  }

  .add-button {
  padding-right: none;
  }

  .thumbnails-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 6px;
  margin-top: 10px;
  }
  

  .checkout-buttons2 {
    background-color: #212121;
  }

  .shop-name {
  font-family: 'IT Caslon No 224, serif,serif';
  font-size: 1.4em;
  width: 100%;
  }

  .radio-buttons-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr); /* Two columns */
  gap: 10px; /* Space between buttons */
  }

  .sale-items-single {
  height: 80%;
  margin-bottom: 100px;
  /* background-image: url('../image/yellw.png'); */
  flex-direction: column;
  /* align-items: ; */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 100px;
  }

  .responsive-image {
  width: 70%;    
  height: auto;
  display: block;   
  /* scale: 100%; */
  /* object-fit: cover; */
  border-radius: 3px;
  margin: 0 auto;   /* Centers the image */
  overflow-x: none;
  }

  .collection-title {
  font-size: 24px;
  margin-bottom: 15px;
  width: 100%;
  letter-spacing: -0.05em;
  font-stretch: 95%;
  /* text-decoration: underline; */
  font-family: 'ITC Caslon No 224, serif';
  font-weight: 300;
  }

  .project-details {
  /* display: flex;
  flex-direction: row; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  width: 100%;
  text-align: center;
  }

  .details-box {
  display: flex;
  /* grid-template-rows: repeat(2, 1fr); */
  align-items: center;
  flex-direction: row;
  width: 100%;
  }
  .project-title1 {
  font-size: 1.4em;
  margin-bottom: 15px;
  width: auto;
  letter-spacing: -0.05em;
  font-stretch: 105%;
  text-decoration: underline;
  font-family: 'ITC Caslon No 224, serif';
  font-weight: 400;
  }

  .flagship-section {
  height: auto;
  width: auto;
  top: 0px;
  /* padding-top: 4%;
  padding-bottom: 4%;
  padding-left: 2%;
  padding-right: 2% */
  
}

.flagship-image {
  width: 100%;
  scale: 130%;
  /* width: auto; */
  top: 3%;
  border-radius: 9px;;
}

  /* .checkout-buttons2 {
  width: 80%;
  scale: 70%;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #000000;
  color: white;
  font-size: 1rem;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease;
  } */

  .cart-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  }

  /* .project-sampler1 {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 90%;
  border-radius: 9px;
  margin: 20px auto;
  margin-bottom: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 20px;
  align-items: stretch;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  } */

  .project-sampler-item {
  position: relative;
  width: 100%;
  height: 87%;
  display: flex;
  flex-direction: row;
  max-width: auto;
  /* border-radius: 9px; */
  margin: 20px auto;
  /* margin-bottom: 5px; */
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 20px;
  align-items: stretch;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

  .sale-items {
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(2, 1fr);
  margin-top: -120%;
  scale: 80%;
  max-width: 100%;
  margin-bottom: 40px;
  }

  .qty {
    width: 30px;
  }

  .project-description2 {
  margin-bottom: 115px;
  margin-top: 10px;
  font-size: 0.9em;
  text-align: justify;
  width: 100%;
  align-content: center;
  padding: 0px 0px 0px 0px;
  line-height: 1.6;
}

.section-project-description {
  height: 30%;
  width: 80%;
  background-color: #F7F7F7;
  margin-top: 65px;
  font-size: 1em;
  /* padding-left: 10%; */
  text-align: justify;
  color: #000000;
  font-weight: 700;
  align-content: center;
  line-height: 1.6;
   background-image: linear-gradient(to bottom, transparent , #fff );
  /* animation: fadeOut 3s forwards; */
  animation-timeline: scroll();
}

  .project-sampler {
  width: 100%;
  height: 100%;
  max-width: 300px;
}

  .feature-container {
    overflow: hidden;
  }

  .project-title {
    font-size: 1.8em;
  }

  .project-description {
    font-size: 0.8em;
  }

  .subTitle {
    font-size: 1.4em;
  }

  .section-title {
  width: 60%;
  }

  .feature {
    font-size: 1em;
  }

  .app-store-badge {
    width: 160px;
  }

  #contact {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



  #contact-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 1.6rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
}

@media (max-width: 480px) {
  .project-sample-container {
    max-width: 90%;
    margin: 10px;
    padding: 0px;
    /* padding-top: 10px; */
  }

  .shop-description {
  /* font-family: 'IT Caslon No 224, serif,serif'; */
  writing-mode:sideways-lr;
  width: 65%;
  }


  .section-project-description2 {
  height: 30%;
  width: 100%;
  background-color: #F7F7F7;
  margin-top: 85px;
  /* margin-bottom: 185px; */
  font-size: 1em;
  padding-left: 10%;
  padding-right: 10%;
  text-align: justify;
  color: #000000;
  justify-content: center;
  font-weight: 700;
  align-content: center;
  line-height: 1.6;
  /* opacity: 1;
  animation: fadeOut 1s backwards;
  animation-timeline: scroll(); */
  }

  .add-button {
  padding-right: none;
  }

  .thumbnails-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 6px;
  margin-top: 10px;
  }

  .shop-name {
  font-family: 'IT Caslon No 224, serif,serif';
  font-size: 1.4em;
  width: 100%;
  }

  .responsive-image {
  width: 80%;
  height: auto;
  scale: 100%;
  max-width: 100%;
  display: block;
  border-radius: 3px;
  margin: 0 auto;
  }

    .project-sampler-item {
  position: relative;
  width: 90%;
  height: 87%;
  display: flex;
  flex-direction: row;
  max-width: auto;
  /* border-radius: 9px; */
  margin: 20px auto;
  /* margin-bottom: 5px; */
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 20px;
  align-items: stretch;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .sale-items {
  flex-direction: column;
  /* flex-direction: row; */
  grid-template-rows: repeat(2, 1fr);
  height: 800;
  margin-top: -120%;
  margin-bottom: 40px;
  }

  .cart-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  }

  .flagship-section {
  height: auto;
  width: auto;
  top: 0px;
  /* padding-top: 4%; */
  /* padding-bottom: 4%; */
  /* padding-left: 2%; */
  /* padding-right: 2% */
  }

  .flagship-image {
  width: 100%;
  scale: 130%;
  top: 3%;
  border-radius: 9px;
  }

  .project-details {
  display: flex;
  flex-direction: column;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  width: 100%;
  text-align: center;
  }

  .details-box {
  display: flex;
  /* grid-template-rows: repeat(2, 1fr); */
  align-items: center;
  flex-direction: column;
  width: 100%;
  }

  .checkout-buttons2 {
  width: 100%;
  scale: 80%;
  height: 30%;
  /* padding: 10px 15px; */
  border: none;
  border-radius: 4px;
  background-color: #212121;
  color: white;
  font-size: 0.9rem;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease;
  }

  .project-description2 {
  margin-bottom: 115px;
  margin-top: 10px;
  font-size: 0.9em;
  text-align: justify;
  width: 80%;
  align-content: center;
  padding: 0px 0px 0px 0px;
  line-height: 1.6;
}

.project-title1 {
  font-size: 1.4em;
  margin-bottom: 15px;
  width: auto;
  letter-spacing: -0.05em;
  font-stretch: 105%;
  text-decoration: underline;
  font-family: 'ITC Caslon No 224, serif';
  font-weight: 400;
  }

  .project-sampler {
    width: 100%;
    height: 100%;
    max-width: 85%;
}

  .feature-container {
    overflow-x: hidden;
  }

  .project-title {
    font-size: 1.6em;
  }
  

  .section-title {
  width: 60%;
  }

  .project-description {
    font-size: 0.8em;
  }

  .subTitle {
    padding-left: 25px;
    font-size: 1.3em;
  }

  .feature {
    font-size: 0.9em;
    padding-left: 15px;
  }

  .app-store-badge {
    width: 160px;
  }

  #contact {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  width: 80%;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

  #contact-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #212121;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
}


