
.navbar {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  /* padding: 10px 20px; */
}

.rt-image {
  width: 50px;  /* Adjust width to make it small */
  height: auto; /* Maintains aspect ratio */
  
  vertical-align: middle; /* Aligns image vertically with text */
  margin-left:-25px; /* Optional: Add a small space between 'A' and the image */
  margin-top:-25px; /* Optional: Add a small space between 'A' and the image */
}

.navbar-brand {
    color: white !important;
    width: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: right;
    scale: 50%;
    background-color: #ffffff;
    /* margin-top: 20px; */
  }

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  width: auto;
  min-width: 250px;
  /* top: 120px; */
  margin-top: 1px;
  opacity: .95;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 1;
}

.nav-link:hover .dropdown-content {
  display: block;
  cursor: alias;
  background-image: linear-gradient(to bottom, transparent, #fbfafa );
  cursor: alias;
}

.dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover {
  /* color: black; */
  color: rgb(252, 0, 0);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.hoverDiv {
  position: absolute;
  top: 0vh;
  gap: .76%;
  font-size: 19px;
  left: 0;
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 1;
  background-image: linear-gradient(to bottom, transparent 1%, #fff 99%);
  transition: background-color 0.6s ease;
}

.highlight {
  font-weight: bold; /* Make the text bold */
}

.raised {
  position: relative;
  top: -0.4em; /* Adjust the position of the 't' */
  font-size: 15px; /* Increase the font size */

}

.hoverDiv:hover {
  /* background-color: #ffffff; */
  opacity: 0.95;
  /* cursor: pointer; */
  /* background-image: linear-gradient(to bottom, transparent 1%, #fff 99%); */
  
}

.banner-title {
  position: absolute;
  top: -55px;
  padding-top: 40px; 
  /* padding-bottom: 30px; */
  left: 0;
  width: 100%;
  /* padding-left: 15px; */
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  letter-spacing: 25px;
  color: #000000;
  paint-order: stroke fill;
  -webkit-text-stroke: 4px rgba(221, 220, 214, 0.612);
  font-size: 25px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 1; */
}

.banner-container {
  /* position: relative; */
  height: 100;
  width: 100%;
  margin: 0;
  padding: 0;
  /* margin-top: 30px; */
}

.banner-img {
  width: 100%;
  height: 50%;
  z-index: 1;
  background-image: linear-gradient(to top, transparent, #ffffff );
}

.header-background {
  height: 0vh;
  width:  '100%',
  /* height: '130%', */
}

.navbar-brand-img {
  width: 150px;
  height: auto;
  /* background-image: linear-gradient(to bottom, #f7f7f7, #ffffff); */
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-nav.open {
  display: flex;
  flex-direction: row;
  padding-left: 10%;
}

.nav-link {
  color: rgb(29, 29, 29);
  margin-right: 20px;
  font-size: 1.23cap;
  height: auto;
  margin-top: 85px;
  /* padding-top: 40px; */
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
}

.tossing2 {
  font-family: 'Montserrat', sans-serif;
  align-items: center;
  width: 99%;
  font-size: 2rem;
  font-weight: 500;
  color: #0b0d0e; 
  animation: toss 4s infinite;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

@keyframes toss {
  0%, 100% { transform: rotate(0.4deg); }
  /* 75% { transform: rotate(-1deg); } */
  /* 0%, 100% { transform: rotate(1.1deg); } */
  75% { transform: rotate(-0.5deg); }
}

.nav-link img {
  width: auto;
  height: 30px;
}

.nav-button {
  color: rgb(29, 29, 29);
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  height: auto;
  /* padding-top: 26px; */
  /* opacity: 1; */
  /* cursor: alias; */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-link:hover .nav-button {
  transform: scale(1.06);
  color: rgb(177, 34, 34);
}


.hamburger-container {
  position: relative;
  padding-top: 22px;
}

.burger {
  width: 2rem;
  height: 0.25rem;
  border-radius: 9px;
  background-color: black;
  transform-origin: 1px;
  transition: all 0.3s linear;
}
.hamburger {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;
}

.hamburger.open .burger1 {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .burger2 {
  opacity: 0;
}

.hamburger.open .burger3 {
  transform: rotate(-45deg) translate(5px, -5px);
}

.drawerPaper {
  background-color: transparent;
  color: #ffffff;
  width: 120px;
  max-height: 70%;
  font-size: 2rem;
  flex-direction: row;
  align-items:center;
  justify-content:center;
  border-radius: 12px;
}

.navbar-links .nav-link {
  color: rgb(29, 29, 29);
  margin-right: 20px;
  font-size: 0.73rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
}


.drawer-links .MuiListItemText-primary {
  font-size: 1rem; /* Adjust font size as needed */
}

/* .navbar-links .nav-link {
  color: rgb(29, 29, 29);
  margin-right: 20px;
  font-size: 0.73rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
} */


@media (max-width: 768px) {

  .navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  /* padding-right: 50px; */
  }

  .banner-title {
  position: absolute;
  /* top: -30px; */
  /* padding-top: 20px;  */
  /* padding-bottom: 30px; */
  left: 0;
  width: auto;
  font-family: 'Bodoni, serif';
  font-weight: normal;
  color: #000000;
  font-size: 25px;
  height: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 1; */
}

.banner-container {
  /* position: relative; */
  height: 100;
  width: 100%;
  margin: 0;
  padding: 0;
  /* margin-top: 30px; */
}

.banner-img {
  width: 60%;
  height: auto;
}

  .navbar-nav {
    display: none;
    /* display: flex; */
    flex-direction: row;
    align-items: center;
  }

  .navbar-nav.open {
    display: flex;
  }

  .nav-link {
    width: 100%;
    text-align: center;
    font-size: 0.67cap;
    margin: 0px 10;
  }
}

/* NavHamburger.css */
.hamburger-container {
  position: relative;
  padding-top: 22px;
  padding-left: 80%;
}

.hamburger {
  display: flex;
  cursor: pointer;
  width: 28px;
  height: 28px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.burger {
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: all 0.3s ease;
}

.hamburger.open .burger1 {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .burger2 {
  opacity: 0;
}

.hamburger.open .burger3 {
  transform: rotate(-45deg) translate(5px, -5px);
}

.drawerPaper {
  background-color: transparent;
  color: #ffffff;
  width: 20%;
}

@media (max-width: 768px) {
  .hamburger-container {
  position: relative;
  top: -10px;
  padding-left: 160%;
  }

  .hamburger {
    display: flex;
    /* width: 25px;
    height: 25px; */
  }

  /* .navbar {
  padding-right: 50px;
} */

  .nav-link {
    font-size: 0.67cap;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 3px;
    font-size: 24px;
    cursor: pointer;
  }
  .drawerPaper {
    background-color: transparent;
    color: #ffffff;
    width: 50%;
  }
}



@media (max-width: 480px) {
  .hamburger {
    display: flex;
  }

  .hamburger-container {
  position: relative;
  top: -10px;
  padding-left: 120%;
  }

  .navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  }

  .navbar {
  padding-right: 10px;
}

  .nav-link {
    font-size: 0.67cap;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    /* padding-right: 3px; */
    font-size: 24px;
    cursor: pointer;
  }

  .drawerPaper {
  /* padding: 20px; */
  background-color: transparent;
  color: #ffffff;
  width: 50%;
  max-height: 40%;
}
}

