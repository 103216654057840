.Heading {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: auto;
}

.job-title {
  font-size: 2rem;
}

.headshot {
  border-radius: 9px;
}

.payments {
  border-radius: 9px;
}

.payments-section {
  padding-left: 20%;
}

.payments-section2 {
  padding-left: 0%;
  scale: 80%;
}

.Bod {
  flex-grow: 1;
  text-align: center;
  /* background-color: rgb(252, 252, 252); */
  overflow-x: none;
  /* padding-top: 15vh; */
  max-height: calc(100vh - 180px);
}

.prjLink {
  color: rgb(98, 106, 180);
}

.prjLink:hover {
  color: #2a1691;
}

.tossing {
  font-family: "Montserrat", sans-serif;
  align-items: center;
  width: 99%;
  font-size: 4rem;
  font-weight: 500;
  color: #0b0d0e;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.tossing2 {
  font-size: medium;
  font-family: "Montserrat", sans-serif;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  font-size: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

@keyframes toss {
  0%,
  100% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-0.5deg);
  }
}

.Content {
  font-size: medium;
  font-family: "Montserrat", sans-serif;
  align-items: center;
  width: auto;
  padding-top: 30px;
  margin: 0 auto;
  font-size: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.Content2 {
  font-size: medium;
  font-family: "Avenir Next", sans-serif;
  font-size: 16px;
  justify-content: center;
  width: auto;
  overflow-y: none;
  margin: 0 auto;
  /* font-size: 2rem; */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}



.upper-section {
  text-align: center;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* border-radius: 20px; */
  width: 100%;
  height: 93%;
  /* animation: slideOut 2s; */
  align-items: center;
  margin-bottom: 10%;
}

@keyframes slideIn {
    0% { 
      transform: translate(-100%);
      opacity: 0; }
    50% { 
      transform: translate(0);
      opacity: 1; }
}
@keyframes slideOut {
    0% { opacity: 1; }
    10% { opacity: 0; }
}

.slide-out {
  animation: fadeIn 2s ;
}

.slide-in {
  animation: fadeIn 2s ;
}

.upper-section-success {
  text-align: center;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  margin-top: 9%;
  height: 63%;
  align-items: center;
}

.success-section {
  align-items: center;
  height: 80vh;
  justify-content: center;
}

.upper-section-success p {
  font-size: 2rem;
  /* padding: 15%; */
  width: 70%;
  text-decoration: double;
  padding-left: 14%;
}

.mailchimp {
  width: 60%;
  padding: 0% 20% 10%;
  justify-content: center;
}

.mc_embed_signup {
  align-items: center;
}

.upper-section2 {
  /* padding-top: 20px; */
  text-align: center;
  /* padding-left: 45px; */
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  border-radius: 20px;
  width: 80%;
  height: 83%;
  align-items: center;
}

.fixed-bottom-right-button {
  position: fixed;
  bottom: -10px;
  right: 2.5%;
  width: 350px;
  height: 50px;
  /* padding: 15px 123px; */
  font-size: 1.5rem;
  background-color: #212121;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: height 0.4s ease;
  transition: all 0.3s ease;
}

.fixed-bottom-right-container {
  align-items: center;
}

.fixed-bottom-right-button-mobile {
  position: fixed;
  bottom: -10px;
  right: 10%;
  width: 350px;
  height: 50px;
  /* padding: 15px 123px; */
  font-size: 1.5rem;
  background-color: #212121;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: height 0.4s ease;
  transition: all 0.3s ease;
}

.header {
  margin-top: 1%;
  text-align: center;
  font-family: 'IT Caslon No 224, serif,serif';
  font-size: 24px;
  font-weight: 0;
  width: 100%;
  width: 100%;
}

.expanded-cart {
  position: fixed;
  bottom: -5px;
  max-height: 100%;
  width: 37%;
  max-width: auto;
  right: -5px;
  padding: 20px;
  font-size: 1.8rem;
  border: 2.4px solid #282828;
  background-color: rgb(255, 255, 255);
  /* box-shadow: inset 3px 6px 10px rgba(0, 0, 0, 0.27); */
  color: #000000;
  overflow-y: auto;
  border-radius: 9px;
  height: 75%;
  -webkit-transition: height 0.4s ease;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.cart-line-item {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  /* justify-content: center; */
  scale: 70%;
  /* gap: 10px; */
}

.cart-items {
  flex: 1;
  height: 50px;
  /* overflow-y: auto;  */
  /* width: 120%; */
}

.fixed-bottom-right-button:hover {
  background-color: #121212;
}

.upper-section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #97bbf9;
}

.lower-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  border-radius: 20px;
  width: 100%;
  height: 93%;
  align-items: center;
}

.lower-section2 {
  display: grid;
  flex-direction: column;
  /* grid-template-rows: repeat(1, 1fr); Two equal-width columns */
  row-gap: 90px;
  border-radius: 20px;
  width: 100%;
  height: 73%;
  padding-top: 40px;
  align-items: center;
  animation: fadeIn 1.5s backwards; /* Scroll Animation */
  animation-timeline: scroll(); /* Scroll Animation */
  /* background-image: url('./image/yellw.png'); */
  background-size: stretch;
  background-color: #f7f7f7;
  background-position: center;
  background-repeat: repeat;
}

/* Scroll Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
}

.App-header {
  background-color: #ffffff;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  font-size: calc(15px + 2vmin);
  color: white;
  /* padding: 10px 0px ; */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .Content {
    flex-direction: column;
  }

  .Heading {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow-y: auto;
  }

  .tossing {
    font-size: 3rem; /* Adjust font size for smaller screens */
  }

  .job-title {
    font-size: 1.2rem;
  }

  .expanded-cart {
    position: fixed;
    bottom: 30px;
    height: auto;
    max-height: 60%;
    max-width: 60%;
    right: 20px;
    padding: 20px 30px;
    font-size: 1.2rem;
  }

  .cart-line-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    justify-content: center;
    scale: 60%;
    gap: 7px;
  }

  .fixed-bottom-right-button {
    position: fixed;
    bottom: -10px;
    right: 20px;
    padding: 20px 123px;
    font-size: 1.2rem;
    background-color: #000000;
    color: white;
    border: none;
    /* border-radius: 5px; */
    cursor: pointer;
  }

  .upper-section2 {
    padding-top: 20px;
    text-align: center;
    padding-left: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    /* border-radius: 20px; */
    width: auto;
    height: 93%;
    /* padding-top: 20px; */
    align-items: center;
    /* overflow-y: auto; */
  }

  .lower-section2 {
    display: flex;
    flex-direction: column;
    /* flex-grow: 1; */
    /* display: grid; */
    row-gap: 90px;
    border-radius: 20px;
    width: 100%;
    height: 73%;
    padding-top: 40px;
    align-items: center;
    /* overflow-y: auto; */
  }

  .upper-section {
    /* padding: 20px; */
    text-align: center;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* border-radius: 20px; */
    width: auto;
    height: 93%;
    padding-top: 20px;
    align-items: stretch;
    overflow-y: auto;
  }
  .upper-section2 {
    padding-top: 20px;
    text-align: left;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* border-radius: 20px; */
    width: 80%;
    height: 93%;
    align-items: center;
  }

  .upper-section-bio {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  width: 80%;
  margin-top: 40px;
  height: auto;
  justify-content: space-between; /* Ensure even spacing */
  align-items: center; /* Align items vertically */
}

.bio-image {
  width: 50%; /* Image takes up half of the container */
  height: auto;
  scale: 10%;
  object-position: right;
  /* border-radius: 10px; */
  object-fit: cover; /* Ensures the image doesn't stretch */
}

.bio-text {
  width: 50%; /* Text takes up half of the container */
  margin-left: 20px;
  text-align: left; /* Left-align text */
  font-size: 16px;
  line-height: 1.5;
}
  .App-header {
    width: auto;
    display: flex;
    max-width: 120px;
    align-items: center;
    justify-content: left;
    font-size: calc(15px + 2vmin);
    color: white;
    padding: 10px;
  }

  .Content {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 17vh; */
    width: 100%;
    height: auto;
  }

  .Bod {
    /* padding-top: 5vh; */
    overflow: none;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .Content {
    flex-direction: column;
  }
  .tossing {
    font-size: 3rem; /* Adjust font size for smaller screens */
  }
  .job-title {
    font-size: 1.2rem;
  }

  .expanded-cart {
    position: fixed;
    bottom: 2px;
    /* right: 20px; */
    height: auto;
    max-height: 60%;
    /* width: auto; */
    max-width: 25%;
    padding: 20px 140px;
    font-size: 1.2rem;
  }

  .cart-line-item {
    display: flex;
    justify-content: flex-end;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    scale: auto;
    gap: 7px;
  }

  .fixed-bottom-right-button {
    display: flex;
  }

  .fixed-bottom-right-button {
    position: fixed;
    bottom: -15px;
    right: 13%;
    padding: 12px 103px;
    font-size: 1.2rem;
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
  }

  .upper-section2 {
    padding-top: 20px;
    text-align: center;
    padding-left: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    border-radius: 20px;
    width: 80%;
    height: 93%;
    /* padding-top: 20px; */
    align-items: center;
    /* overflow-y: auto; */
  }
  .lower-section2 {
    display: flex;
    flex-direction: column;
    /* flex-grow: 1; */
    /* display: grid; */
    row-gap: 90px;
    border-radius: 20px;
    width: 100%;
    height: 73%;
    padding-top: 40px;
    align-items: center;
    /* overflow-y: auto; */
  }

  .upper-section {
    padding: 20px;
    text-align: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    place-items: center;
    flex-grow: 1;
    border-radius: 20px;
    width: 80%;
    height: 93%;
    padding-top: 20px;
    align-items: stretch;
    overflow-y: auto;
  }
  .upper-section2 {
    padding-top: 20px;
    text-align: left;
    background-color: #fff;
    display: flex;
    scale: 80%;
    flex-direction: column;
    flex-grow: 1;
    border-radius: 20px;
    width: 80%;
    height: 93%;
    align-items: center;
  }

  .App-header {
    /* background-color: #343a40; */
    width: fit-content;
    display: flex;
    /* max-width: 120px; */
    /* height: 80px; */
    align-items: center;
    justify-content: left;
    font-size: calc(15px + 2vmin);
    color: white;
    padding: 10px;
  }

  .Content {
    font-size: 1rem;
    width: 100%; /* Ensure full width usage */
    margin: 0 auto;
    padding-bottom: 20px; /* Add padding to avoid cutoff */
  }

  .Bod {
    /* padding-top: 5vh; */
    overflow: auto;
    width: 100%;
  }
}
